import React from 'react'

// Video for flexibleMediaContent
import governVideoFeature from '../../../resources/images/hanzo/product/01_govern.mp4'
import exploreVideoFeature from '../../../resources/images/hanzo/product/02_discovery.mp4'
import processVideoFeature from '../../../resources/images/hanzo/product/03_process.mp4'
import integrateVideoFeature from '../../../resources/images/hanzo/product/04_integrate.mp4'

import governVideoFeatureOptimized from '../../../resources/images/hanzo/product/01_govern.webm'
import exploreVideoFeatureOptimized from '../../../resources/images/hanzo/product/02_discovery.webm'
import processVideoFeatureOptimized from '../../../resources/images/hanzo/product/03_process.webm'
import integrateVideoFeatureOptimized from '../../../resources/images/hanzo/product/04_integrate.webm'

// Image for graphicsAndLogos
import graphicAndLogosImage from '../../../resources/images/hanzo/product/architecture-diagram.webp'
import adminUserImg from '../../../resources/images/hanzo/testimonials/admin.webp'
import { DemoIcon, PricingPlansIcon } from '../../lib/components/basics/icons'

// HERO PROPERTIES (Used in HeroModule component)
export const heroProps = {
    title: (
        <>
            All your streams. <br />
            One screen.
        </>
    ),
    description: (
        <p>
            40,000+ developers govern, discover, process, and integrate data streams across multiple
            Kafkas, using Lenses.
        </p>
    ),
    primaryCtaLink: '/product/pricing',
    primaryCtaLabel: 'See pricing',
    secondaryCtaLink: '/request-demo',
    secondaryCtaLabel: 'Get a demo',
}

export const howItWorksProps = {
    title: 'How does it work?',
    description: <p>Install Lenses. Connect to your Kafkas. Go.</p>,
    primaryCtaLink: 'https://docs.lenses.io/latest',
    primaryCtaLabel: 'Read docs',
}

// TESTIMONIALS (Used in Testimonials component)
export const testimonialsIntro = ''
export const testimonials = [
    {
        title: 'Developers',
        userIconSrc: adminUserImg,
        name: 'Phil Falkenholm',
        job: 'Director of Infrastructure and Security, Arity',
        quote: (
            <div>
                “Using Lenses helps us <b>know where to look</b> for the data we need so we can{' '}
                <b>see what’s working</b> across systems and apps.”
            </div>
        ),
        buttonLabel: 'Success Stories',
        buttonLink: '/customers',
    },
]

// FEATURES TITLE (Used in FixedTitle component)
export const featuresTitle = 'Let developers do their thing'

// PREFOOTER (Used in PreFooter component)
export const preFooterTitle = 'One product. Unlimited productivity.'

// GRAPHICS AND LOGOS (Used in GraphicLogos component)
export const graphicsAndLogos = {
    title: 'Bring your own architecture',

    graphicSrc: graphicAndLogosImage,
    graphicAltText: 'architecture diagram',
}

// FLEXIBLE MODULE PROPERTIES (Used in FlexibleModule component)

// Single image used for the flexible module
export const flexibleImage = {
    imgSrc: 'https://codyhouse.co/demo-tutorials/stacking-cards/assets/img/img-2.jpg',
    altText: 'Image description',
}

// First text block for flexible module
export const flexible1TextBlocks = [
    {
        pretitle: 'GOVERN',
        title: 'Take data permissions off the platform team’s plate ',
        description: `
            <p>Developers get the access they need from data owners on a single screen, without concerns about platform resilience.</p>
            <p>Enjoy <b>Global IAM</b>, <b>Data Policies</b>, <b>Audit Logs</b>, and <b>Data Masking</b>, designed for a decentralized Kafka landscape.</p>`,
    },
]

// Text blocks for flexible module (Used in FlexibleModule component)
export const graphicLogosTextBlocks = [
    {
        pretitle: 'GOVERN',
        title: 'Unify data access and permissions globally',
        description: `
            <p>Have confidence by applying the most powerful and granular permission model across all streaming technologies, with <b>Multi-Kafka IAM</b> and <b>Streaming Data Policies:</b></p>
            <p>From opening up all topics in dev, to assigning a team to manage a type of resource in production.</p>`,
        video: {
            src: governVideoFeature,
            srcOptimized: governVideoFeatureOptimized,
        },
    },
    {
        pretitle: 'DISCOVER',
        title: 'Find and explore streaming data across domains, with SQL',
        description: `
            <p>Data can stream, but it can’t hide with Lenses.</p>
            <p>Teams can discover data across different departments, compare topics across domains, and troubleshoot data to use in downstream applications with <b>Global Topic Catalog</b> and <b>SQL Studio.</b></p>`,
        video: {
            src: exploreVideoFeature,
            srcOptimized: exploreVideoFeatureOptimized,
        },
    },
    {
        pretitle: 'PROCESS',
        title: 'Prepare data for easier consumption with SQL',
        description: `
            <p>Shifting your development left means earlier data ownership. Give developers a common language for preparing streaming data with <b>SQL Processors.</b></p>
            <p>Transform, reshape, aggregate and join streams, then run them across your entire streaming ecosystem. Boom.</p>`,
        video: {
            src: processVideoFeature,
            srcOptimized: processVideoFeatureOptimized,
        },
    },
    {
        pretitle: 'INTEGRATE',
        title: 'Deploy data streams independently, anywhere your business needs',
        description: `
            <p>Don’t wait to integrate your streaming data across your organization, and check connectors are working. Do it yourself.</p>
            <p>Manage connectors and consumer groups, and monitor their health from one screen.</p>`,
        video: {
            src: integrateVideoFeature,
            srcOptimized: integrateVideoFeatureOptimized,
        },
    },
]

// CARDS DATA FOR PREFOOTER (Used in PreFooter component)
export const cardsDataPrefooter = [
    {
        title: 'Simple scalable plans',
        type: 'primary' as const,
        description: ['Start free, with plans that scale with your streaming adoption.'],
        buttonText: 'See pricing',
        href: '/product/pricing',
        icon: <PricingPlansIcon key='pricingIcon' />,
    },
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: 'secondary' as const,
        description: [
            'Get a guided tour of Lenses',
            'Receive a trial key to install on your clusters',
            'Perfect for production and large-scale streaming deployments.',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
]
